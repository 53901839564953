<template>
  <div class="box">
    <div class="banerText">
      <div @click="prev" class="homeText">{{ i18n.User.home }}</div>
      <div class="icon homeText">></div>
      <div @click="toUrinfo" class="homeText">{{ i18n.User.personal }}</div>
      <div class="icon homeText">></div>
      <div class="userInfoText">{{ i18n.User.ChangeYourPhone }}</div>
    </div>
    <div class="content">
      <el-card class="box-card">
        <div class="headertext">
          <ul class="steps">
            <li class="active">{{ i18n.User.Authentication }}</li>
            <li :class="isActive == true ? '' : 'active'">
              {{ i18n.User.Binding }}
            </li>
            <li :class="isType == false ? '' : 'active'">
              {{ i18n.User.succeeded }}
            </li>
          </ul>
        </div>
        <div v-if="!isType">
          <div v-if="isActive" class="headertext">
            <div class="phonetext">{{ i18n.User.Verification }}{{ phone }}</div>
            <div class="inputtext">
              <input v-model="phoneCode" :placeholder="i18n.User.code" />
              <el-button
                class="btn"
                :disabled="smsSendFlag"
                type="info"
                plain
                @click="handleSendCode"
                >{{ smsBtnText }}</el-button
              >
            </div>
            <div class="errtext" v-if="errText">
              {{ i18n.User.VerificationError }}
            </div>
            <div class="confirmBtn">
              <el-button
                class="btn"
                :class="!disabled ? 'actived' : ' '"
                :type="type"
                round
                :disabled="disabled"
                @click="submitCheck"
                >{{ i18n.User.next }}</el-button
              >
            </div>
          </div>
          <div v-if="!isActive" class="box">
            <el-row>
              <el-col :span="6">
                <span class="text">{{ i18n.User.NewMobile }}</span>
              </el-col>
              <el-col :span="18">
                <el-input
                  :placeholder="i18n.User.please3"
                  v-model="newPhoneNum"
                  class="input"
                >
                  <el-select
                    v-model="area"
                    slot="prepend"
                    :placeholder="i18n.User.select"
                  >
                    <el-option
                      v-for="item in areaList"
                      :key="item.id"
                      :label="`+${item.cipher}`"
                      :value="item.id"
                      >{{ item.en }}</el-option
                    >
                  </el-select>
                </el-input>
              </el-col>
            </el-row>
            <br />
            <el-row>
              <el-col :span="6"
                ><span class="text">{{ i18n.User.VerificationCode }}</span>
              </el-col>
              <el-col :span="18">
                <el-input
                  :placeholder="i18n.User.please4"
                  v-model="newPhoneCode"
                  class="input"
                >
                  <el-button
                    :disabled="newSmsSendFlag"
                    class="codeButton"
                    @click="handleNewSendCode"
                    slot="append"
                  >
                    {{ newSmsBtnText }}</el-button
                  >
                </el-input></el-col
              >
            </el-row>
            <div v-if="errText" class="errtext">
              {{ i18n.User.VerificationCodeError }}
            </div>
            <div class="confirmBtn">
              <el-button
                class="btn"
                :class="!newDisabled ? 'actived' : ' '"
                :type="newType"
                round
                :disabled="newDisabled"
                @click="submit"
                >{{ i18n.User.next }}</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="isType" class="box">
          <div class="iconfont">&#xe662;</div>
          <div class="successText" style="text-align: center">
            {{ i18n.User.Replacement }}
          </div>
          <el-button
            class="successBtn"
            :class="!disabled ? 'actived' : ' '"
            :type="type"
            round
            :disabled="disabled"
            @click="toUrinfo"
            >{{ i18n.User.complete }}</el-button
          >
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { apiCheckAreacode } from '@/api/api.js'
import i18n from '@/i18n/i18n'

import {
  getPhoneCode,
  phoneCodeCheck,
  getNewPhoneCode,
  bindNewPhone
} from '@/api/userInfo.js'
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  data() {
    return {
      areaList: [], //区域列表
      phoneCode: '',
      smsBtnText:
        i18n.locale === 'ch' ? '发送验证码' : 'Send verification code',
      newSmsBtnText:
        i18n.locale === 'ch' ? '发送验证码' : 'Send verification code',
      errText: false,
      phoneNum: '',
      smsSendFlag: false,
      newSmsSendFlag: false,
      area: '',
      isActive: true,
      newPhoneNum: '',
      newPhoneCode: '',
      newtimeId: null,
      timeId: null,
      isType: false
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    }),
    i18n() {
      return this.$t('messages')
    },
    type() {
      if (this.disabled) {
        return 'info'
      } else {
        return 'primary'
      }
    },
    disabled() {
      if (!this.phoneCode) {
        return true
      } else {
        return false
      }
    },
    newType() {
      if (this.newDisabled) {
        return 'info'
      } else {
        return 'primary'
      }
    },
    newDisabled() {
      if (this.newPhoneCode && this.newPhoneNum) {
        return false
      } else {
        return true
      }
    },
    phone() {
      if (this.userInfo && this.userInfo.phone) {
        const pat = /(\d{3})\d*(\d{4})/
        return this.userInfo.phone.replace(pat, '$1****$2')
      } else {
        return ''
      }
    }
  },
  created() {
    this.getAreaCode()
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    // 获取区域区号【选择国家区域】
    getAreaCode() {
      apiCheckAreacode().then((res) => {
        this.areaList = res.data
        this.area = (this.areaList[0] || {}).id
      })
    },
    prev() {
      this.$router.push({ name: 'home' })
    },
    toUrinfo() {
      this.$router.push({ name: 'userInfo' })
    },
    submitCheck() {
      phoneCodeCheck({ sms_code: this.phoneCode }).then((res) => {
        if (res.code == 200) {
          this.$layer.msg(res.msg)
          this.errText = false
          this.smsBtnText =
            i18n.locale === 'ch' ? '发送验证码' : 'Send verification code'
          setTimeout(() => {
            this.isActive = false
          }, 500)
        } else {
          this.errText = true
        }
      })
    },
    submit() {
      const areaData = this.areaList.find((m) => m.id == this.area)
      if (
        !this.newPhoneNum.trim() ||
        !eval(areaData.match).test(this.newPhoneNum)
      ) {
        this.errMsg =
          i18n.locale === 'ch'
            ? '手机号码格式错误'
            : 'Mobile phone number format error'
        return false
      }
      bindNewPhone({
        phone: this.newPhoneNum,
        country_code: areaData.cipher,
        sms_code: this.newPhoneCode
      }).then((res) => {
        if (res.code == 200) {
          this.isType = true
          this.GetUserInfo()
          this.$layer.msg(res.msg)
        }
      })
    },
    handleNewSendCode() {
      this.newSmsSendFlag = true
      const areaData = this.areaList.find((m) => m.id == this.area)
      if (
        !this.newPhoneNum.trim() ||
        !eval(areaData.match).test(this.newPhoneNum)
      ) {
        this.errMsg =
          i18n.locale === 'ch'
            ? '手机号码格式错误'
            : 'Mobile phone number format error'
        return false
      }
      getNewPhoneCode({
        phone: this.newPhoneNum,
        country_code: areaData.cipher
      }).then((res) => {
        if (res.code == 200) {
          this.$layer.msg(
            i18n.locale === 'ch'
              ? '发送验证码成功'
              : 'Sending verification code succeeded'
          )
          let second = 60
          this.newSmsBtnText = `(${second}s)${
            i18n.locale === 'ch' ? '再次发送' : 'Send again'
          }`
          this.newtimeId = setInterval(() => {
            second -= 1
            this.newSmsBtnText = `(${second}s)${
              i18n.locale === 'ch' ? '再次发送' : 'Send again'
            }`
            if (second === 0) {
              clearInterval(this.newtimeId)
              this.newtimeId = 0
              this.newSmsSendFlag = false
              this.newSmsBtnText =
                i18n.locale === 'ch' ? '发送验证码' : 'Send verification code'
            }
          }, 1000)
        } else {
          this.newSmsSendFlag = false
        }
      })
    },
    handleSendCode() {
      this.smsSendFlag = true
      getPhoneCode().then((res) => {
        if (res.code == 200) {
          this.$layer.msg(
            i18n.locale === 'ch'
              ? '发送验证码成功'
              : 'Sending verification code succeeded'
          )
          let second = 60
          this.smsBtnText = `(${second}s)${
            i18n.locale === 'ch' ? '再次发送' : 'Send again'
          }`
          this.timeId = setInterval(() => {
            second -= 1
            this.smsBtnText = `(${second}s)${
              i18n.locale === 'ch' ? '再次发送' : 'Send again'
            }`
            if (second === 0) {
              clearInterval(this.timeId)
              this.timeId = 0
              this.smsSendFlag = false
              this.smsBtnText =
                i18n.locale === 'ch' ? '发送验证码' : 'Send verification code'
            }
          }, 1000)
        } else {
          this.smsSendFlag = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont {
  color: #36c626;
  font-size: 45px;
  text-align: center;
}
.successText {
  font-size: 14px;

  font-weight: 400;
  line-height: 29px;
  color: #3a3c4d;
}
.successBtn {
  width: 223px;
  height: 42px;
  background: #3b86ff;
  border-radius: 30px;
  border-color: #3b86ff;
  margin-top: 81px;
  margin-left: 48px;
}
.box {
  .banerText {
    margin-top: 26px;
    display: flex;
    font-size: 14px;

    font-weight: 400;
    line-height: 17px;
    color: #1f1f40;
    .homeText {
      color: #9c9c9c;
      font-size: 14px;
    }
    .icon {
      margin-left: 19px;
      margin-right: 19px;
    }
  }
  .box-card {
    width: 1260px;
    height: 427px;
    margin-top: 19px;
    .headertext {
      display: flex;
      justify-content: center;
      font-size: 14px;

      font-weight: 400;
      color: #1f1f40;
      .phonetext {
        margin-top: 76px;
      }
      .inputtext {
        // width: 250px;
        position: absolute;
        border-bottom: 1px solid #dcdcdc;
        display: flex;
        box-sizing: border-box;
        margin-top: 120px;
        .btn {
          height: 27px;
          background: #ededed;
          border: none;
          font-size: 13px;
          line-height: 0;
          color: #1f1f40;
          margin-bottom: 4px;
          padding: revert;
        }
        input {
          border: none;
          width: 195px;
          height: 20px;
          line-height: 17px;
        }
      }
      .errtext {
        position: absolute;
        margin-top: 145px;
        margin-left: -185px;
        font-size: 12px;

        font-weight: 400;
        line-height: 40px;
        color: #ff606d;
      }
      .confirmBtn {
        position: absolute;
        margin-top: 233px;
        .btn {
          width: 223px;
          height: 42px;
        }
        .actived {
          background-color: #3b86ff;
          border-color: #3b86ff;
        }
      }
      /* 进度条 */
      /* 创建步骤数字计数器 */
      .steps {
        position: relative;
        counter-reset: step;
        background: #fff;
        margin: 5px 0;
        overflow: hidden;
      }

      /* 步骤描述 */
      .steps li {
        font-size: 14px;

        font-weight: 400;
        color: #1f1f40;
        list-style-type: none;
        text-align: center;
        width: 168px;
        position: relative;
        float: left;
        line-height: 40px;
        padding: 10px 0;
      }
      /* 步骤数字 */
      .steps li:before {
        display: block;
        content: counter(step); /* 设定计数器内容 */
        counter-increment: step; /* 计数器值递增 */
        width: 10px;
        height: 10px;
        background-color: #ccc;
        line-height: 10px;
        border-radius: 10px;
        font-size: 0;
        color: #fff;
        text-align: center;
        font-weight: 600;
        margin: 0 auto 9px auto;
      }

      /* 连接线 */
      .steps li ~ li:after {
        content: '';
        width: 90%;
        height: 3px;
        background-color: #ccc;
        position: absolute;
        left: -45%;
        top: 14px;
        z-index: 0;
      }
      /* 将当前/完成步骤之前的数字及连接线变绿 */
      .steps li.active:before,
      .steps li.active:after {
        background-color: #3b86ff;
      }
    }
    .box {
      width: 330px;
      margin: 0 auto;
      margin-top: 45px;
      .text {
        font-size: 14px;

        font-weight: 400;
        line-height: 29px;
        color: #3a3c4d;
      }
      /deep/.el-input-group__append,
      /deep/.el-input-group__prepend {
        background-color: #fff;
        color: #909399;
        border: none;
        border-bottom: 1px solid #dcdcdc;
        width: 38px;
        border-radius: 0;
      }
      /deep/.el-input__inner {
        border: none;
        border-bottom: 1px solid #dcdcdc;
        border-radius: 0;
      }
      .codeButton {
        width: 104px;
        height: 27px;
        background: #ededed;
        border: none;
        font-size: 13px;
        line-height: 0;
        color: #1f1f40;
        margin-bottom: 4px;
        padding: revert;
      }
      .errtext {
        font-size: 12px;

        font-weight: 400;
        line-height: 40px;
        color: #ff606d;
        padding-left: 80px;
      }
      .confirmBtn {
        margin-left: 48px;
        margin-top: 36px;
        .btn {
          width: 223px;
          height: 42px;
        }
        .actived {
          background-color: #3b86ff;
          border-color: #3b86ff;
        }
      }
    }
  }
}
</style>
